import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Builder } from '@builder.io/react/lite';
import { withChildren } from "@builder.io/react";
import { addIdAttribute } from '@glowforge/builder-components/lib/util/shared-configs';
import styled from 'styled-components'
import Button from '@glowforge/builder-components/lib/button';
import textStyle from '@glowforge/gf-styled/src/components/text.style';
import { createUser } from '../../api/storefront';
import { clientOnly } from '../../util';

const track = (targeting, email) => {
  const name = `account-created-${targeting.toLowerCase()}`;
  clientOnly(() => {
    if (window.analytics) {
      window.analytics.identify({
        email,
      });
      window.analytics.track(name, {
        name,
        email,
      });
    }
  });
};

const StyledDiv = styled.div`
${textStyle}
`

function CreateAccount({
  attributes,
  children,
  dataTestId,
  accountTargeting,
  errorOverride,
  emailPlaceholderText,
  createAccountText,
}) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [accountCreated, setAccountCreated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Builder.isEditing && errorOverride) {
      if (errorOverride === 'account-created') {
        setAccountCreated(true);
      } else {
        setError(errorOverride);
        setAccountCreated(false);
      }
    }
  }, [errorOverride]);

  const validateEmail = (emailToValidate) => {
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return re.test(String(emailToValidate).toLowerCase());
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setError('email-invalid-error');
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await createUser({ email, targeting: accountTargeting });
      if (response.status === 422) {
        setError('email-exists-error');
      } else if (response.status === 500) {
        setError('unknown-error');
      } else {
        track(accountTargeting, email);
        setAccountCreated(true);
      }
    } catch (err) {
      setError('unknown-error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const errorContent = children?.find(child => child.key === error);
  const accountCreatedContent = children?.find(child => child.key === 'account-created');

  if (accountCreated && accountCreatedContent) {
    return (
      <div {...addIdAttribute(dataTestId)}>
        {accountCreatedContent}
      </div>
    );
  }

  return (
    <StyledDiv {...addIdAttribute(dataTestId)} {...attributes}>
      {errorContent && (
        <div className='error-message'>
          {errorContent}
        </div>
      )}
      <input
        type='email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder={emailPlaceholderText}
        required
      />
      <Button
        type='submit'
        disabled={isSubmitting}
        attributes={{onClick: handleSubmit}}       variant='primary'
        text={createAccountText}
        addIcon
        chooseCommonIcon='https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fb08ad2d1eee44230b8f3675858105e12'
        iconSize={24}
        noMobileBoxShadow={false} >
        {isSubmitting ? 'Creating Account...' : createAccountText}
      </Button>
    </StyledDiv>
  );
}

CreateAccount.propTypes = {
  children: PropTypes.node,
  dataTestId: PropTypes.string,
  accountTargeting: PropTypes.string,
  errorOverride: PropTypes.oneOf([
    'unknown-error',
    'email-exists-error',
    'account-created',
    'email-invalid-error',
  ]),
  emailPlaceholderText: PropTypes.string,
  createAccountText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  attributes: PropTypes.object,
};

CreateAccount.defaultProps = {
  children: [],
  dataTestId: null,
  accountTargeting: null,
  errorOverride: null,
  emailPlaceholderText: 'Enter your email',
  createAccountText: 'Create Account',
  attributes: {},
};

export default withChildren(CreateAccount);
